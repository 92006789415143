import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl';

import { slugify } from '../utils'
import HeroTeaserImageBG from '../components/modules/hero-teaser-image-bg';
import SEO from '../components/modules/seo/seo';

const Contact = ({ pageContext: { slug, locale } }) => {

  const intl = useIntl()
  const data = useStaticQuery(query)
  const thankYouPage = data.thankYouPage.nodes.filter(page => page.node_locale === intl.locale)[0]
  const successURL = `${slugify(intl.locale)}${slugify(thankYouPage.slug)}`

  return (
    <div className="page-margin">
      <SEO
        title={intl.formatMessage({ id: "contactTitle" })}
        description={intl.formatMessage({ id: "contactSubline" })}
        slug={slug}
        locale={locale}
      />

      <HeroTeaserImageBG image={data.image}>
        <h1 className="display-2 font-weight-bold text-white text-center">
          <FormattedMessage id="contactTitle" />
        </h1>
        <p className="lead text-muted mb-0 text-center">
          <FormattedMessage id="contactSubline" />
        </p>
      </HeroTeaserImageBG>

      <section className="bg-green-100 pt-2 pt-md-4 pb-7 pb-md-8 border-bottom" id="info">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <a href="#info" className="btn btn-white btn-rounded-circle shadow mt-n10 mt-md-n13">
                <i className="fe fe-arrow-down"></i>
              </a>
            </div>
          </div>
          <div className="row" itemScope itemType="http://schema.org/Organization">
            <div className="col-12 col-md-4 text-center border-gray-300 border-right-md">
              <h6 className="text-uppercase text-gray-700 mb-1">
                <FormattedMessage id="contactLabelAddress" />
              </h6>
              <div className="mb-5 mb-md-0" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                <a href="https://goo.gl/maps/weetu3GUFV4CH7dq7" target="_blank" rel="nofollow noopener noreferrer" className="h4 text-primary">
                  <span itemProp="streetAddress">Blücherstraße 22</span>, <span itemProp="postalCode">10961</span> <span itemProp="addressLocality">Berlin</span>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center border-gray-300 border-right-md">
              <h6 className="text-uppercase text-gray-700 mb-1">
                <FormattedMessage id="contactLabelTelephone" />
              </h6>
              <div className="mb-5 mb-md-0">
                <a href="tel:004930403688360" className="h4" itemProp="telephone">
                  +49 30 403688360
								</a>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center">
              <h6 className="text-uppercase text-gray-700 mb-1">
                <FormattedMessage id="contactLabelEmail" />
              </h6>
              <a href="mailto:office@est8.services" className="h4" itemProp="email">
                office@est8.services
							</a>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-8 pt-md-11 pb-8 pb-md-14 bg-green-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center mb-6">
              <h2 className="blue-500">
                <FormattedMessage id="contactFormTitle" />
              </h2>
              <p className="font-size-lg text-muted mb-7 mb-md-9">
                <FormattedMessage id="contactFormSubline" />
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-12 col-lg-10">
              <form name="contactform" id="contactform" method="POST" data-netlify="true" action={successURL}>
                <input type="hidden" name="form-name" value="contactform" />
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      name="name"
                      labelId="contactFormName"
                      placeholder={intl.formatMessage({ id: "contactFormNamePlaceholder" })}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      name="company"
                      labelId="contactFormCompany"
                      placeholder={intl.formatMessage({ id: "contactFormCompanyPlaceholder" })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextInput
                      type="email"
                      name="email"
                      labelId="contactFormEmail"
                      placeholder={intl.formatMessage({ id: "contactFormEmailPlaceholder" })}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextInput
                      name="phone"
                      labelId="contactFormTelephone"
                      placeholder={intl.formatMessage({ id: "contactFormTelephonePlaceholder" })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-7 mb-md-9">
                      <label className="d-block">
                        <span className="text-muted"><FormattedMessage id="contactFormMessage" /></span>
                        <textarea
                          name="message"
                          rows="5"
                          placeholder={intl.formatMessage({ id: "contactFormMessagePlaceholder" })}
                          className="form-control mt-2"
                        ></textarea>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-auto">
                    <button type="submit" className="btn btn-primary lift">
                      <FormattedMessage id="contactFormButton" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const TextInput = ({ labelId, type = "text", name, placeholder, required = false }) => (
  <div className="form-group mb-6">
    <label className="d-block">
      <span className="text-muted"><FormattedMessage id={labelId} /></span>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        className="form-control mt-2"
      />
    </label>
  </div>
)

const query = graphql`
	{
    thankYouPage: allContentfulPagePage(filter: {contentful_id: {eq: "4KQsvOvL8wisirlfAHDYt0"}}) {
      nodes {
        slug
        node_locale
      }
    }
    image: contentfulAsset(contentful_id: {eq: "5WBOTmSwvI5JW2f17oBkUa"}) {
      background: fluid(maxWidth: 1600, maxHeight: 640) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`

export default Contact
